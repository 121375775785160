<template>
  <div>
    <van-nav-bar left-arrow left-text="返回" @click-left="goBack" title="查看全部网点" />
    <div style="padding:6px 16px 0px;background-color:#fff;margin-top:17px">
      <div class="tit">预约安装工单量：{{reservation.install}}</div>
      <div class="tit">预约维修工单量：{{reservation.repaire}}</div>
    </div>
    <van-cell title="选择日期" :value="date" @click="show = true" is-link />
    <!-- <van-calendar v-model="show" @confirm="onConfirm" :min-date="minDate" /> -->
    <van-popup v-model="show" style="border-radius:10px">
      <div style="width:90vw">
        <van-datetime-picker
          @confirm="confirmFn"
          @cancel="cancelFn"
          v-model="time"
          :type="type === '4' ? 'year-month' : 'date' "
          :title="type === '4' ? '选择年月' : '选择年月日'"
          :min-date="minDate"
          :max-date="maxDate"
        />
      </div>
    </van-popup>
    <div style="margin-top:18px;background-color:#fff;padding-top:6px">
      <div class="list_title">
        <div class="rank">排名</div>
        <div class="website">网点</div>
        <div class="install" @click="insFn">
          安装工单量
          <van-icon :name="isIns?'arrow-down':'arrow-up'" />
        </div>
        <div class="repaire" @click="repairFn">
          维修工单量
          <van-icon :name="isrepair?'arrow-down':'arrow-up'" />
        </div>
      </div>
      <!-- <van-row style="padding:0 16px">
        <van-col span="2">
          <span class="listTitle">排名</span>
        </van-col>
        <van-col span="12">
          <span class="listTitle">网点</span>
        </van-col>
        <van-col span="5" @click="insFn">
          <span class="listTitle">
            安装工单量
            <van-icon :name="isIns?'arrow-down':'arrow-up'" />
          </span>
        </van-col>
        <van-col span="5" @click="repairFn">
          <span class="listTitle">
            维修工单量
            <van-icon :name="isrepair?'arrow-down':'arrow-up'" />
          </span>
        </van-col>
      </van-row>-->
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <div class="list_title" v-for="(item,i) in list" :key="i">
            <div class="rank">{{i+1}}</div>
            <div class="website">{{item.name+'('+item.afterNumber+')'}}</div>
            <div class="install">{{item.azgdl}}</div>
            <div class="repaire">{{item.wxgdl}}</div>
          </div>
          <!-- <van-cell v-for="(item,i) in list" :key="i">
            <van-row>
              <van-col span="2">{{i+1}}</van-col>
              <van-col span="12">
                <div class="listTitle">{{item.name}}</div>
              </van-col>
              <van-col span="5">
                <div style="text-align:right">
                  <div class="fr">{{item.azgdl}}</div>
                </div>
              </van-col>
              <van-col span="5">
                <div style="text-align:right">
                  <div class="fr">{{item.wxgdl}}</div>
                </div>
              </van-col>
            </van-row>
            <van-row>
              <van-col span="2"></van-col>
              <van-col span="12">
                <div class="listTitle">{{item.afterNumber}}</div>
              </van-col>
              <van-col span="5">
                <div style="text-align:right">
                  <div v-if="item.ztazgdl>0" class="upFloat">
                    <img style="width:12px;height:12px" src="../assets/icon/shangsheng.png" alt />
                    {{item.ztazgdl}}%
                  </div>
                  <div v-if="item.ztazgdl<0" class="downFloat">
                    <img style="width:12px;height:12px" src="../assets/icon/down.png" alt />
                    {{-item.ztazgdl}}%
                  </div>
                </div>
              </van-col>
              <van-col span="5">
                <div style="text-align:right">
                  <div v-if="item.ztsbs>0" class="upFloat">
                    <img style="width:12px;height:12px" src="../assets/icon/shangsheng.png" alt />
                    {{item.ztsbs}}%
                  </div>
                  <div v-if="item.ztsbs<0" class="downFloat">
                    <img style="width:12px;height:12px" src="../assets/icon/down.png" alt />
                    {{-item.ztsbs}}%
                  </div>
                </div>
              </van-col>
            </van-row>
          </van-cell>-->
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { getUrlKey } from '@/utils/common'
export default {
  data() {
    return {
      azgdl: 0,
      wxgdl: 0,
      show: false,
      date: '',
      time: '',
      minDate: new Date(2010, 1, 1),
      maxDate: new Date(2030, 10, 1),
      loading: false,
      finished: false,
      refreshing: false,
      isIns: false,
      isrepair: false,
      list: [],
      type: this.$route.query.type,
      startDate: '',
      endDate: ''
    }
  },
  created() {
    console.log(this.$route)
    this.date = this.$route.query.time.replace(/-/g, '/')
    if (this.$route.query.type === '4') {
      let year = this.$route.query.time.slice(0, 4)
      let month = this.$route.query.time.slice(5, 8)
      let day = new Date(year, month, 0).getDate()
      this.startDate = this.date + '/01'
      this.endDate = this.date + '/' + day
    }
  },
  computed: {
    reservation() {
      let install = 0
      let repaire = 0
      this.list.forEach(v => {
        install = install + v.azgdl
        repaire = repaire + v.wxgdl
      })
      return { install, repaire }
    }
  },
  methods: {
    //日期格式化
    formatDate(date) {
      if (this.type === '4') {
        let day = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
        return `${date.getFullYear()}/${date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)}/${day}`
      } else {
        return `${date.getFullYear()}/${date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)}/${
          date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()
        }`
      }
    },
    confirmFn(date) {
      this.show = false
      this.date = this.type === '4' ? this.formatDate(date).slice(0, 7) : this.formatDate(date)
      this.startDate = this.formatDate(date).slice(0, 7) + '/01'
      this.endDate = this.formatDate(date)
      this.getList()
    },
    cancelFn() {
      this.show = false
    },
    //维修筛选
    repairFn() {
      this.isrepair = !this.isrepair
      if (this.isrepair) {
        this.list.sort((a, b) => {
          return b.wxgdl - a.wxgdl
        }) /* 从大到小 */
      } else {
        this.list.sort((a, b) => {
          return a.wxgdl - b.wxgdl
        }) /* 从小到大 */
      }
      // this.isrepair = false;
    },
    //安装筛选
    insFn() {
      this.isIns = !this.isIns
      if (this.isIns) {
        this.list.sort((a, b) => {
          return b.azgdl - a.azgdl
        }) /* 从大到小 */
      } else {
        this.list.sort((a, b) => {
          return a.azgdl - b.azgdl
        }) /* 从小到大 */
      }
    },
    async onRefresh() {
      // 清空列表数据
      this.finished = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      await this.onLoad()
    },
    async onLoad() {
      await this.getList()
    },

    async getList() {
      let params = {
        openid: getUrlKey('openid', window.location.href),
        moduleId: '11',
        type: '家用',
        startDate: this.type === '4' ? this.startDate : this.date,
        endDate: this.type === '4' ? this.endDate : this.date,
        // timeConditionType: 'day',
        timeConditionType: this.type === '4' ? 'month' : 'day',
        sumType: 1
      }
      let azRes = await this.postRequest('/wechat-analysis/summarys', { sumMode: 'install.reserve', ...params })
      let wxRes = await this.postRequest('/wechat-analysis/summarys', { sumMode: 'repair.reserve', ...params })
      console.log(azRes, wxRes)
      let oneData = []
      let twoData = []
      if (azRes.data.length > wxRes.data.length) {
        oneData = azRes.data
        twoData = wxRes.data
      } else {
        oneData = wxRes.data
        twoData = azRes.data
      }
      let data = []
      for (let i = 0; i < oneData.length; i++) {
        let obj = {
          afterNumber: oneData[i].afterNumber,
          name: oneData[i].name,
          azgdl: 0,
          wxgdl: 0
        }
        for (let j = 0; j < twoData.length; j++) {
          if (oneData[i].afterNumber === twoData[j].afterNumber) {
            if (azRes.data.length > wxRes.data.length) {
              obj.azgdl = oneData[i].orderct
              obj.wxgdl = twoData[j].orderct
            } else {
              obj.wxgdl = oneData[i].orderct
              obj.azgdl = twoData[j].orderct
            }
          }
        }
        data.push(obj)
      }
      if (this.refreshing) {
        this.list = []
        this.refreshing = false
      }
      if (data.length === 0) {
        this.list = []
        this.finished = true
      }
      this.list = data
      this.isIns = false
      this.insFn()
      this.loading = false
      if (this.list.length >= data.length) {
        this.finished = true
      }
    },

    goBack() {
      this.$router.back()
    }
  }
}
</script>

<style lang='scss' scoped>
.tit {
  font-weight: 700;
  font-size: 14px;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.listTitle {
  font-weight: 700;
  font-size: 12px;
}
.list_title {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding: 10px 16px;
  font-weight: 700;
  .rank {
    width: 10vw;
  }
  .website {
    width: 38vw;
  }
  .install {
    width: 21vw;
    text-align: right;
  }
  .repaire {
    width: 21vw;
    text-align: right;
  }
}
</style>